"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function create(src, key, id) {
    return new Promise(function (resolve, reject) {
        var scriptElement = document.createElement('script');
        scriptElement.type = 'application/javascript';
        if (id) {
            scriptElement['id'] = id;
        }
        if (key) {
            scriptElement.setAttribute('data-key', key);
        }
        scriptElement.src = src;
        scriptElement.defer = true;
        scriptElement.onload = function () {
            resolve(src);
        };
        scriptElement.onerror = function (event) {
            reject({ event: event, src: src });
        };
        document.body.appendChild(scriptElement);
    });
}
function createScripts() {
    var chatwidget = document.querySelector('#mercury-widget-data');
    create(chatwidget.getAttribute('data-mercury-widget-path'), chatwidget.getAttribute('data-mercury-key'), 'mercury-widget-snippet')
        .then(function () {
        if (chatwidget.getAttribute('data-live-chat-active') === 'true') {
            create(chatwidget.getAttribute('data-mercury-mirror-wrapper')).catch(function (error) {
                console.error("Error create mercury mirror wrapper: ", error.event);
            });
            create(chatwidget.getAttribute('data-mercury-mirror-path')).catch(function (error) {
                console.error("Error create mercury mirror path: ", error.event);
            });
        }
    })
        .catch(function (error) {
        console.error("Error creating mercury widget on ".concat(error.src, " "), error.event);
    });
}
exports.default = createScripts;
document.addEventListener('DOMContentLoaded', function () {
    createScripts();
});
