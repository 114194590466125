window.VWFS = window.VWFS || {};
VWFS.MercuryChat = function() {
	
	console.debug("INFO mercurychat.js loaded");
	
	/**
     * CREATE MERCURY USER
	 * Creates or reads the mercury-user-id from Session-Storage.
	 * The SessionStorage keeps the mercury-user-id until the window or tab is closed.
     */
    this.createMercuryUser = function () {
	
		// GET MERCURY-USER-ID FROM SESSION-STORAGE
		var mercuryUserId = sessionStorage.getItem('mercury.user.id');
	
		if(!mercuryUserId) {
			
			// CREATE MERCURY-USER-ID
			mercuryUserId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
			
			// WRITE MERCURY-USER-ID TO SESSION-STORAGE
			sessionStorage.setItem('mercury.user.id', mercuryUserId);
			
		}
		
		var mercuyChatData = document.querySelector('#mercury-widget-data');
		const brand = mercuyChatData.getAttribute('data-mercury-brand');
		const team = mercuyChatData.getAttribute('data-mercury-team');
		
		// SET MERCURY USER OBJECT AS GLOBAL VARIABLE
		mercuryUser = {
		    userId: mercuryUserId,
		    context: {
		        'global.brand': brand,
		        'global.team': team
		    }
		}
		
	}
	
};

// CREATE MERCURY-USER
new VWFS.MercuryChat().createMercuryUser();
